import baseApiSlice from 'api/baseApiSlice';
import { orderBy } from 'lodash';

const BADGES_API_URL = '/api/badges';
const CLUB_API_URL = '/api/members/clubs';
const FEEDBACK_API_URL = '/api/member/feedback';

const feedbackApiWithTags = baseApiSlice.enhanceEndpoints({
  addTagTypes: ['feedback'],
});

const feedbackApiSlice = feedbackApiWithTags.injectEndpoints({
  endpoints: builder => ({
    getClubs: builder.query({
      query: () => ({
        url: `${CLUB_API_URL}/user_clubs/`,
      }),
    }),

    getClubMembers: builder.query({
      query: ({ clubId }) => ({
        url: `${CLUB_API_URL}/${clubId}/members/`,
      }),
      transformResponse: (response: any) => {
        return orderBy(response, [member => member.name.toLowerCase()], ['asc']);
      },
    }),

    requestFeedback: builder.mutation({
      query: data => ({
        url: `${FEEDBACK_API_URL}/request/`,
        method: 'POST',
        data,
      }),
    }),

    giveFeedback: builder.mutation({
      query: data => ({
        url: `${FEEDBACK_API_URL}/response/`,
        method: 'POST',
        data,
      }),
    }),

    getFeedbackBadges: builder.query({
      query: () => ({
        url: `${BADGES_API_URL}/feedback_badges/?badge_parent_type=learner_badge`,
      }),
    }),

    deleteFeedback: builder.mutation({
      query: id => ({
        url: `${FEEDBACK_API_URL}/response/${id}/`,
        method: 'DELETE',
      }),
    }),

    updateFeedback: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `${FEEDBACK_API_URL}/response/${id}/`,
        method: 'PUT',
        data,
      }),
    }),
  }),
});

export const {
  useGetFeedbackBadgesQuery,
  useGetClubsQuery,
  useGetClubMembersQuery,
  useGiveFeedbackMutation,
  useRequestFeedbackMutation,
  useDeleteFeedbackMutation,
  useUpdateFeedbackMutation,
} = feedbackApiSlice;

export default feedbackApiSlice;
