import { FC, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import { getQueryParam, getUserName, parseDate } from 'utils/utility';
import { toast } from 'react-toastify';
import { generatePath, useHistory, Link } from 'react-router-dom';

import { DashboardRoutes as Routes } from 'routes';
import Loader from 'elements/Loader';
import { EvaluationContentHolder, HeaderHolder, IconHolder } from './style';
import iconEvaluators from 'assets/images/icons/icon-evaluators.svg';
import iconSpeakers from 'assets/images/icons/icon-speakers.svg';
import iconFormSpeakers from 'assets/images/icons/speakers-form-icon.svg';
import iconFormEvaluators from 'assets/images/icons/evaluators-form-icon.svg';

import {
  getEvaluationFormSelector,
  getReceivedEvaluationFormSelector,
} from 'pages/Dashboard/ducks/selectors';
import {
  getEvaluationForms,
  getReceivedEvaluationForms,
  shareEvaluationForm,
} from 'pages/Dashboard/ducks/evaluationFormsSlice';
import { getStringWithSeperator } from 'utils/utility';
import { useAppContext } from 'contexts/AppContext';

import ShareFormModal from './ShareFormModal';
import EvaluationFeedbackSection from './EvaluationFeedbackSection';
import { Grid, Typography } from '@mui/material';
import { useIsSpeechCraftMFE } from 'hooks';
import { HOC } from 'elements/Modal';

const INITIAL_STATE = {
  clubId: '',
  clubMembers: [],
  speechTitle: '',
  speechDate: '',
  request: '',
  evaluatorName: '',
};

const ShareEvaluationModal = HOC(ShareFormModal);

const EvaluationFeedback: FC<any> = () => {
  const [selectedForm, setSelectedForm] = useState('');
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isDirectFeedback, setIsDirectFeedback] = useState(false);
  const [formToShare, setFormToShare] = useState<any>({});
  useState<any>(INITIAL_STATE);

  const history = useHistory();
  const dispatch = useDispatch<any>();
  const { event } = useAppContext();
  const isSpeechCraftMFE = useIsSpeechCraftMFE();

  const { data: evaluationForms, isEvaluationFormsLoading } = useSelector(
    getEvaluationFormSelector,
  );
  const {
    data: revievedEvaluationForms,
    isLoading: isReceivedEvaluationFormsLoading,
  } = useSelector(getReceivedEvaluationFormSelector);

  const isLoading =
    isEvaluationFormsLoading || isReceivedEvaluationFormsLoading;
  const isEvaluationForm = selectedForm === '' || selectedForm === 'evalForms';
  const isFeedbackEvaluationForm =
    selectedForm === '' || selectedForm === 'feedbackForms';

  useEffect(() => {
    dispatch(getEvaluationForms({}));
    dispatch(getReceivedEvaluationForms({}));
  }, [dispatch]);

  const handleGetNextData = (formsData: any) => {
    const next = get(formsData, 'next', null) || '';

    if (!next) {
      return;
    }

    const { page }: any = getQueryParam(next);

    if (selectedForm === 'evalForms') {
      dispatch(getEvaluationForms({ page }));
    } else {
      dispatch(getReceivedEvaluationForms({ page }));
    }
  };

  const resetState = () => {
    setIsShareModalOpen(false);
    setFormToShare({});
    setIsDirectFeedback(false);
  };

  const handleOpenShareModal = (
    evaluationForm: any,
    isDirectFeedback: any = false,
  ) => {
    setIsShareModalOpen(true);
    setIsDirectFeedback(isDirectFeedback);
    setFormToShare(evaluationForm);
  };

  const handleCloseShareModal = () => {
    resetState();
  };

  const handleSubmitForm = async (
    isDirectFeedback = false,
    values: any,
    updatedValues: any,
    setSubmitting: Function,
  ) => {
    const { clubMembers, request, clubId, speechTitle, speechDate } =
      updatedValues;
    if (isDirectFeedback) {
      setSubmitting(false);
      history.push({
        pathname: generatePath(Routes.DIGITAT_EVALUATION_FORM.path, {
          id: formToShare.id,
        }),
        state: { ...values, clubId: clubId.id, clubMembers: clubMembers.id },
        search: '?type=feedback&status=draft',
      });

      resetState();
      return;
    }

    const data = {
      event: event?.id || '',
      evaluatorsList: clubMembers.map(({ id }: any) => id),
      evaluationForm: formToShare.id,
      description: request,
      club: clubId.id,
      speechLog: '',
      speechTitle: speechTitle,
      ...(speechDate && {
        speechDate: parseDate(speechDate).toISOString(),
      }),
      action: 'save-email',
    };

    const formReviewers = getStringWithSeperator(
      clubMembers.map((member: any) => getUserName(member)),
    );

    try {
      await dispatch(shareEvaluationForm(data)).unwrap();
      toast.success(
        `${formToShare.title} Resource was successfully sent to ${formReviewers}.`,
      );
    } catch (error: any) {
      toast.error(error?.message);
    } finally {
      resetState();
      setSubmitting(false);
    }
  };

  if (isLoading) {
    return <Loader position='relative' />;
  }

  const shareTitle = isDirectFeedback ? 'Complete' : 'Send to My Evaluator:';

  return (
    <div>
      <EvaluationContentHolder>
        <Grid container spacing={6} mb={6} className='evaluation-details'>
          <Grid item xs={12} md={6} mb={2}>
            <HeaderHolder>
              <IconHolder>
                <img src={iconEvaluators} alt='Evaluators Icon' />
              </IconHolder>
              <Typography variant='h3'>Evaluators</Typography>
            </HeaderHolder>
            <Typography className='text'>
              Complete and send a{' '}
              <strong className='info-text'>Digital Evaluation Form</strong> to
              your assigned{' '}
              {isSpeechCraftMFE
                ? 'Speechcrafter'
                : 'speaker (use during your club meeting)'}
              . Use the{' '}
              <img
                className='form-access-icon'
                src={iconFormEvaluators}
                alt='Form Icon'
              />{' '}
              icon to access the form.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} mb={2}>
            <HeaderHolder>
              <IconHolder>
                <img src={iconSpeakers} alt='Speakers Icon' />
              </IconHolder>
              <Typography variant='h3'>Speakers</Typography>
            </HeaderHolder>
            <Typography className='text'>
              Send a{' '}
              <strong className='info-text'>Digital Evaluation Form</strong> for
              your speech to your assigned evaluator (before your{' '}
              {isSpeechCraftMFE ? 'speech' : 'club meeting'}). View{' '}
              <strong className='info-text'>Digital Evaluation Forms</strong>{' '}
              completed for you by your evaluators in the Received Feedback
              section{isSpeechCraftMFE ? ' below' : ''}. Use the{' '}
              <img
                className='form-access-icon'
                src={iconFormSpeakers}
                alt='Form Icon'
              />{' '}
              icon to access the form.
            </Typography>
          </Grid>
        </Grid>
        <Typography mb={5} className='footer-text'>
          Digital forms are available below, access the{' '}
          <Link
            to={`${generatePath(Routes.DASHBOARD.path, {
              activeSection: 'resources',
              activeSubSection: 'resources-details',
            })}?${new URLSearchParams({
              category: 'Evaluation Resources',
            }).toString()}`}
          >
            PDF evaluation forms here
          </Link>
          .
        </Typography>
      </EvaluationContentHolder>
      {isEvaluationForm && (
        <EvaluationFeedbackSection
          title='Digital Evaluation Forms'
          data={evaluationForms}
          handleActionButton={() =>
            setSelectedForm(selectedForm ? '' : 'evalForms')
          }
          selectedForm={selectedForm}
          handleGetNextData={handleGetNextData}
          handleOpenShareModal={handleOpenShareModal}
        />
      )}
      {isFeedbackEvaluationForm && (
        <EvaluationFeedbackSection
          isSubmittedForm
          title='Received Feedback'
          data={revievedEvaluationForms}
          selectedForm={selectedForm}
          handleGetNextData={handleGetNextData}
          handleActionButton={() =>
            setSelectedForm(selectedForm ? '' : 'feedbackForms')
          }
        />
      )}
      {isShareModalOpen && (
        <ShareEvaluationModal
          title={`${shareTitle} ${formToShare?.title}`}
          handleClose={handleCloseShareModal}
          handleSubmitForm={handleSubmitForm}
          isDirectFeedback={isDirectFeedback}
        />
      )}
    </div>
  );
};

export default EvaluationFeedback;
