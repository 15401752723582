import React, { FC, useEffect, useState } from 'react';
import { FormikHelpers, useFormik } from 'formik';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import dayjs, { Dayjs } from 'dayjs';
import { useDispatch } from 'react-redux';
// @ts-ignore
import { getAuthenticatedUser } from '@edx/frontend-platform/auth';
import { filter, first, size, sortBy } from 'lodash';

import DatePicker from 'elements/DatePicker';
import TextField from 'elements/Form/TextField';
import DropdownField from 'elements/DropdownField';
import { validation } from 'pages/Dashboard/components/Achievements/validationSchema';
import Button from 'elements/Button';
import { toggleConfirmation } from 'pages/Modals/ducks/slice';

import { ButtonWrapper } from 'pages/Dashboard/components/UpdateSpeechHistory/style';
import FormControlLabel from 'elements/FormControlLabel/FormControlLabel';
import {
  useGetClubsQuery,
  useGetClubMembersQuery,
} from 'pages/Dashboard/components/Feedback/feedbackApi';

import { useGetEvaluationFormsQuery } from 'api/achievementApi';
import { getUserName, parseDate } from 'utils/utility';
import AutoComplete from 'elements/AutoComplete';

const validateForm = (values: any, setFieldError: any) => {
  const { action, speechDate, club, evaluator, evaluationForm } = values;

  if (action !== 'save-email') {
    return false;
  }

  const errorMessage = 'Field is required';
  const hasErrors = !speechDate || !club || !evaluator || !evaluationForm;

  if (!speechDate) {
    setFieldError('speechDate', errorMessage);
  }

  if (!club) {
    setFieldError('club', errorMessage);
  }

  if (!evaluator) {
    setFieldError('evaluator', errorMessage);
  }

  if (!evaluationForm) {
    setFieldError('evaluationForm', errorMessage);
  }

  return hasErrors;
};

const UpdateSpeechHistory: FC<any> = ({ speechHistory = {}, handleUpdate }) => {
  const dispatch = useDispatch();
  const user = getAuthenticatedUser();

  const [clubId, setClubId] = useState('');

  // const { evaluationForm } = speechHistory;

  const formik = useFormik({
    initialValues: {
      ...speechHistory,
      club: "",
      evaluator: "",
      evaluationForm: "",
    },
    enableReinitialize: true,
    validationSchema: validation,
    onSubmit: (
      values: any,
      { setSubmitting, setFieldError }: FormikHelpers<any>,
    ) => {
      handleSubmit(values, setSubmitting);
    },
  });

  const {
    setFieldValue,
    values: formikValues,
    dirty,
    setFieldTouched,
    touched,
    errors,
    isValid,
  }: any = formik;

  const { data: evaluationForms = [], isFetching: isFetchingEvalluationForms } =
    useGetEvaluationFormsQuery();
  const { data: clubData = [], isFetching: isFetchingClubs } = useGetClubsQuery(
    {},
  );
  const { data: evaluatorsData = [], isFetching: isFetchingEvaluators } =
    useGetClubMembersQuery({ clubId }, { skip: clubId === ''});

  const clubOptions = clubData.reduce(
    (acc: any, { name, id }: any) => [
      ...acc,
      {
        name: name,
        value: id,
      },
    ],
    [],
  );

  const evaluators = isFetchingEvaluators
    ? []
    : evaluatorsData.filter((cm: any) => cm.id !== user.userId);
  const evauatorsOptions = evaluators.reduce(
    (acc: any, user: any) => [
      ...acc,
      {
        name: getUserName(user),
        value: user.id,
      },
    ],
    [],
  );

  const evaluationFormsOptions = sortBy(evaluationForms, option =>
    option.title.toLowerCase(),
  ).reduce(
    (acc: any, { title, id }: any) => [
      ...acc,
      {
        name: title,
        id: id,
      },
    ],
    [],
  );

  const handleChangeDate = (newValue: Dayjs | null) => {
    setFieldValue(
      'speechDate',
      newValue ? dayjs(newValue).format('YYYY-MM-DD') : null,
    );
    setFieldTouched('speechDate', true, false);
  };

  const handleUpdateField = (event: any) => {
    const { name, value } = event.target;

    setFieldValue(name, value);
    setFieldTouched(name, true, false);

    // if (name === 'club') {
    //   setClubId(value);
    //   setFieldValue('evaluator', '', false);
    //   setFieldTouched(name, false, false);
    // }
  };

  const handleSubmit = (values: any, setSubmitting: any) => {
    setSubmitting(true);
    dispatch(
      toggleConfirmation({
        name: 'updateHistorySpeech',
        data: {
          ...values,
          club: values.club?.id,
          evaluator: values.evaluator?.id,
          evaluation_form: values.evaluationForm?.id,
          speechDate: parseDate(values.speechDate).toISOString(),
        },
      } as any),
    );
    handleUpdate();
  };

  const handleChangeClub = (value: any) => {
    setFieldValue('evaluator', null, false);
    setFieldValue('club', value);
    if(!value?.id) {
      setClubId('');
      setFieldTouched('club', true, false);
    } else {
      setFieldTouched('club', false, false);
      setClubId(value?.id);
    }
  };

  useEffect(() => {    
    if(speechHistory.club && size(clubData) > 0 && formikValues.club === "") {
      const selectedClub = filter(clubData, clubItem => clubItem.id === speechHistory.club)
      setClubId(speechHistory.club);
      setFieldValue('club', first(selectedClub));
    }
  }, [speechHistory.club, clubData, formikValues.club]);

  useEffect(() => {
    if(speechHistory.evaluator && size(evaluators) > 0 && formikValues.evaluator === "") {
      const selectedEvaluator = filter(evaluators, item => item.id === speechHistory.evaluator)
      setFieldValue('evaluator', first(selectedEvaluator));
    }
  }, [speechHistory.evaluator, evaluators, formikValues.evaluator]);

  useEffect(() => {    
    if(speechHistory.evaluationForm && size(evaluationFormsOptions) > 0 && formikValues.evaluationForm === "") {
      const selectedEvaluationForm = filter(evaluationFormsOptions, item => item.id === speechHistory.evaluationForm.id)
      setFieldValue('evaluationForm', first(selectedEvaluationForm));
    }
  }, [speechHistory.evaluationForm, evaluationFormsOptions, formikValues.evaluationForm]);


  return (
    <>
      <Grid container rowSpacing={4}>
        <Grid item xs={12}>
          <FormControlLabel
            label='Speech Title'
            labelPlacement='top'
            name='speechTitle'
            control={
              <TextField
                name='speechTitle'
                value={formikValues.speechTitle}
                className={`${formikValues.speechTitle ? 'has-data' : ''}`}
                helperText={touched['speechTitle'] && errors['speechTitle']}
                error={!!touched['speechTitle'] && !!errors['speechTitle']}
                onChange={handleUpdateField}
              />
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            label='Date'
            labelPlacement='top'
            control={
              <DatePicker
                label='Date'
                value={formikValues.speechDate}
                handleChange={handleChangeDate}
                name='speechDate'
                className={`primary-color-icon ${
                  formikValues.speechDate ? 'has-data' : ''
                }`}
                helperText={touched['speechDate'] && errors['speechDate']}
                error={!!touched['speechDate'] && !!errors['speechDate']}
              />
            }
          />
        </Grid>
        <Grid item xs={12}>
          {/* <FormControlLabel
            label='Club'
            labelPlacement='top'
            id='club'
            control={
              <DropdownField
                value={formikValues.club}
                options={clubOptions}
                handleChange={handleUpdateField}
                name='club'
                labelId='club'
                className={`${formikValues.club ? 'has-data' : ''}`}
                helperText={touched['club'] && errors['club']}
                error={!!touched['club'] && !!errors['club']}
                isLoading={isFetchingClubs}
                placeholder='Select a club'
              />
            }
          /> */}
          <FormControlLabel
            label='Club'
            labelPlacement='top'
            id='club'
            control={
              <AutoComplete
                id='select-club'
                options={clubData || []}
                loading={isFetchingClubs}
                loadingText='Loading Club'
                defaultValue={formikValues.club}
                getOptionLabel={(option: any) => option.name}
                selectedOptions={handleChangeClub}
                placeholder='Select a club'
                name='club'
                className={`${formikValues.club ? 'has-data' : ''}`}
                helperText={touched['club'] && (errors['club']?.name || errors['club'])}
                error={!!touched['club'] && (!!errors['club']?.name || !!errors['club'])}
              />
            }
          />
        </Grid>
        <Grid item xs={12}>
          {/* <FormControlLabel
            label='Evaluator'
            labelPlacement='top'
            id='evaluator'
            control={
              <DropdownField
                value={formikValues.evaluator}
                options={evauatorsOptions}
                handleChange={handleUpdateField}
                name='evaluator'
                labelId="evaluator"
                className={`${formikValues.evaluator ? 'has-data' : ''}`}
                helperText={touched['evaluator'] && errors['evaluator']}
                error={!!touched['evaluator'] && !!errors['evaluator']}
                isLoading={isFetchingEvaluators}
                disabled={!formikValues.club}
                placeholder='Select member(s)'
              />
            }
          /> */}
          <FormControlLabel
            label='Evaluator'
            labelPlacement='top'
            id='evaluator'
            control={
              <AutoComplete
                id='select-evaluator'
                options={evaluators || []}
                loading={isFetchingEvaluators}
                loadingText="Loading Evaluator"
                defaultValue={formikValues.evaluator}
                getOptionLabel={(option: any) => option.name}
                selectedOptions={(e: any) => {
                  setFieldValue('evaluator', e);
                  setFieldTouched('evaluator', true, false);
                }}
                placeholder="Select member(s)"
                name='evaluator'
                className={`${formikValues.evaluator ? 'has-data' : ''}`}
                helperText={touched['evaluator'] && (errors['evaluator']?.name || errors['evaluator'])}
                error={!!touched['evaluator'] && (!!errors['evaluator']?.name || !!errors['evaluator'])}
              />
            }
          />
        </Grid>
        <Grid item xs={12}>
          {/* <FormControlLabel
            label='Evaluation Form'
            labelPlacement='top'
            id="EvaluationForm"
            control={
              <DropdownField
                value={formikValues.evaluationForm}
                options={evaluationFormsOptions}
                handleChange={handleUpdateField}
                name='evaluationForm'
                labelId='EvaluationForm'
                className={`${formikValues.evaluationForm ? 'has-data' : ''}`}
                helperText={
                  touched['evaluationForm'] && errors['evaluationForm']
                }
                error={
                  !!touched['evaluationForm'] && !!errors['evaluationForm']
                }
                isLoading={isFetchingEvalluationForms}
                placeholder='Select evaluation form'
              />
            }
          /> */}

          <FormControlLabel
            label='Evaluation Form'
            labelPlacement='top'
            id='EvaluationForm'
            control={
              <AutoComplete
                id='select-evaluation-form'
                options={evaluationFormsOptions || []}
                loading={isFetchingEvalluationForms}
                loadingText="Loading Evaluation Form"
                defaultValue={formikValues.evaluationForm}
                getOptionLabel={(option: any) => option.name}
                selectedOptions={(e: any) => {
                  setFieldValue('evaluationForm', e);
                  setFieldTouched('evaluationForm', true, false);
                }}
                placeholder="Select evaluation form"
                name='evaluationForm'
                className={`${formikValues.evaluationForm ? 'has-data' : ''}`}
                helperText={touched['evaluationForm'] && (errors['evaluationForm']?.name || errors['evaluationForm'])}
                error={!!touched['evaluationForm'] && (!!errors['evaluationForm']?.name || !!errors['evaluationForm'])}
              />
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            label='Request'
            labelPlacement='top'
            id='request'
            control={
              <TextField
                multiline
                inputProps={{'aria-labelledby': 'request'}}
                name='request'
                value={formikValues.request}
                className={`${formikValues.request ? 'has-data' : ''}`}
                helperText={touched['request'] && errors['request']}
                error={!!touched['request'] && !!errors['request']}
                onChange={handleUpdateField}
                maxRows={1}
                placeholder='Example: Please provide feedback on my recent speech.'
              />
            }
          />
        </Grid>
      </Grid>
      <ButtonWrapper>
        <Button
          variant='contained'
          onClick={() => {
            setFieldValue('action', 'save-email');
            formik.handleSubmit();
          }}
        >
          Save &amp; email
        </Button>
        <Button
          variant='contained'
          onClick={() => {
            setFieldValue('action', 'save');
            formik.handleSubmit();
          }}
        >
          Save
        </Button>
      </ButtonWrapper>
    </>
  );
};

export default UpdateSpeechHistory;
