import { Box, Grid, Skeleton } from '@mui/material';
import { memo, FunctionComponent } from 'react';
import { CardItemSkeleton, SkeletonWrapper } from '../style';

const CardSkeleton: FunctionComponent<any> = () => {
  return (
    <SkeletonWrapper>
      <Grid container spacing={3}>
        {
          [1, 2, 3, 4].map( (index: any) => (
            <Grid item xs={12} sm={4} md={3} key={index}>
            <CardItemSkeleton>
              <Box className="card-image">
                <Skeleton variant='rectangular' width="100%" height="100%" />
              </Box>
              <Box className="card-title">
                <Skeleton variant='rectangular' className='card-title-item' width={120} height={25} />
              </Box>
            </CardItemSkeleton>
            </Grid>
          ))
        }
      </Grid>
      
    </SkeletonWrapper>
  );
};

export default memo(CardSkeleton);
