import { FunctionComponent, useEffect, useCallback, useState, createElement } from 'react';
import { isEmpty, get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { MenuList, Stack, Typography } from '@mui/material';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import Button from 'elements/Button';
import Loader from 'elements/Loader';
import { getPlatformName, getQueryParam } from 'utils/utility';
import DropdownFieldV2 from 'elements/DropdownFieldV2';
import Breadcrumb from 'components/Breadcrumb';
import FormControlLabel from 'elements/FormControlLabel/FormControlLabel';
import {
  getAllNotifications,
  resetNotifications,
  markAllAsRead,
} from 'pages/Notifications/ducks/slice';
import { getAllNotificationsSelector } from 'pages/Notifications/ducks/selectors';
import { useLazyGetUnreadNotificationQuery, useLazyGetNotificationDetailsQuery } from 'api/notificationApi';
import { DashboardRoutes as Routes } from 'routes';
import { useIsSpeechCraftMFE } from 'hooks';
import GiveFeedback from 'pages/Dashboard/components/Feedback/MemberFeedback/GiveFeedback';
import SpeechcraftGiveFeedback from 'pages/Dashboard/components/Feedback/MemberFeedback/SpeechcraftGiveFeedback';
import { HOC } from 'elements/Modal';
import DenyDetails from 'pages/Requests/DenyDetails';
import { toast } from 'react-toastify';

import NotificationItem from './NotificationItem';
import {
  Container,
  CustomPaper,
  FilterWrapper,
  SortFieldWrapper,
} from './style';

export const NOTIFICATIONS_SORT_OPTIONS = [
  { label: 'All', value: '' },
  { label: 'Unread', value: 'unread' },
  { label: 'Read', value: 'read' },
];

const links = [
  {
    label: 'Home',
    url: `${getPlatformName()}`,
  },
  {
    label: 'Notifications',
  },
];

const Notifications: FunctionComponent<any> = () => {
  const [sortType, setSortType] = useState('');
  const [filterApiController, setFilterApiController] = useState<any>();
  const [isRequestedFeedback, setIsRequestedFeedback] = useState(false);
  const [feedbackToUpdate, setFeedbackToUpdate] = useState<any>({});
  const [giveModalOpen, setGiveModalOpen] = useState(false);
  const [isEditModlaOpen, setIsEditModlaOpen] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const routerParams = useParams<any>();
  const slug = get(routerParams, 'slug');
  const isSpeechCraftMFE = useIsSpeechCraftMFE();
  const [denyDetailsModalOpen, setDenyDetailsModalOpen] = useState(false);
  const toggleDenyDetailsModal = () => {
    setDenyDetailsModalOpen(prev => !prev);
    history.push(generatePath(Routes.NOTIFICATIONS.path));
  }
  const shouldOpenModal = true;
  const DenyDetailsModal = HOC(DenyDetails);

  const [isLoadingReadAll, setIsLoadingReadAll] = useState(false);

  const [triggerQuery]: any = useLazyGetUnreadNotificationQuery();
  const [
    getNotificationDetails,
    { isFetching, data: feedback, isSuccess },
  ]: any = useLazyGetNotificationDetailsQuery();
  const { feedbackRequest, feedback: feedbackText, isDeleted } = feedback || {};
  const { data, isLoading } = useSelector(getAllNotificationsSelector);

  const handleRequestedFeedback = () => setIsRequestedFeedback(false);
  const handleOpenGiveModal = () => setGiveModalOpen(true);
  const handleCloseGiveModal = () => setGiveModalOpen(false);
  const handleEditFeedback = (feedback: any) => {
    setFeedbackToUpdate(feedback);
    setIsEditModlaOpen(true);
    handleOpenGiveModal();
  };
  const resetEditModal = () => {
    setFeedbackToUpdate({});
    setIsEditModlaOpen(false);
    history.push(generatePath(Routes.NOTIFICATIONS.path));
  };

  const handleGetNextData = useCallback(() => {
    const next = get(data, 'next', null) || '';

    if (!next) {
      return;
    }

    const { page }: any = getQueryParam(next);
    dispatch(getAllNotifications({ page, filter: sortType }));
  }, [data, dispatch, sortType]);

  useEffect(() => {
    if (!slug) {
      return;
    }
    getNotificationDetails(slug);
  }, [getNotificationDetails, slug]);

  useEffect(() => {
    if (isFetching) {
      return;
    }

    if(feedback && isSuccess && feedback?.status === "Denied") {
      setDenyDetailsModalOpen(true);
      return;
    }

    if (isEmpty(feedbackText) && isSuccess && !isDeleted) {
      setIsRequestedFeedback(true);
    }
    if (!isEmpty(feedbackText) && isSuccess) {
      handleEditFeedback(feedback);
    }
  }, [isFetching, feedbackText, isDeleted, isSuccess]);

  useEffect(() => {
    if (!isFetching && isRequestedFeedback) {
      handleOpenGiveModal();
    }
  }, [isFetching, isRequestedFeedback]);

  const handleUpdateSortType = (event: any) => {
    const { value } = event.target;
    setSortType(value);
  };

  const getAllNotification = useCallback(
    params => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const controller = new AbortController();
      // Cancel previous reequest on updating filters
      filterApiController?.abort();

      dispatch(resetNotifications());
      dispatch(getAllNotifications({ ...params, signal: controller.signal }));
      setFilterApiController(controller);
    },
    [dispatch, filterApiController],
  );

  useEffect(() => {
    const params = {
      page: 1,
      filter: sortType,
      resetData: true,
    };
    getAllNotification(params);
  }, [sortType]);

  const handleMarkAllAsRead = async () => {
    setIsLoadingReadAll(true);
    await dispatch(markAllAsRead({}));
    toast.success('All notifications marked as read');
    triggerQuery({});
    setIsLoadingReadAll(false);
  };


  return (
    <Container>
      <Breadcrumb links={links} />
      <FilterWrapper>
        <Typography variant='h3' component='h1'>
          Notifications {get(data, 'count') && `(${get(data, 'count', 0)})`}
        </Typography>
      </FilterWrapper>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={2} sx={{ mb: 2 }}
        alignItems={{ xs: 'flex-end', sm: 'center' }}
        justifyContent='space-between'
        mt={4}
      >
        <Button
          onClick={handleMarkAllAsRead}
          variant='text'
          disabled={isLoadingReadAll}
        >
          Mark all as read
        </Button>

        <SortFieldWrapper>
          <FormControlLabel
            label='Sort by : '
            labelPlacement='start'
            sx={{ minWidth: 120 }}
            className='h-40'
            size='small'
            id='sortBy'
            control={
              <DropdownFieldV2
                labelId='sortBy'
                value={sortType}
                options={NOTIFICATIONS_SORT_OPTIONS}
                handleChange={handleUpdateSortType}
                name='sortBy'
                className='v2'
                popUpMenuClassName='selected-checked-icon'
              />
            }
          />
        </SortFieldWrapper>
      </Stack>
      <>
        {isFetching && !(isLoading && isEmpty(data)) ? (
          <Loader position='absolute' background='transparent' />
        ) : null}
      </>
      <CustomPaper square sx={{ py: 3 }}>
        {isEmpty(data) && isLoading ? (
          <Loader position='relative' />
        ) : (
          <InfiniteScroll
            dataLength={get(data, 'results', []).length}
            next={handleGetNextData}
            hasMore={get(data, 'next', '') ? true : false}
            loader={
              <div className='loader' key={0}>
                <Loader />
              </div>
            }
            scrollableTarget='scrollableDiv'
          >
            <MenuList component="nav" aria-busy='true'>
              {data?.results?.map((notification: any) => (
                <NotificationItem notification={notification} shouldOpenModal={shouldOpenModal} />
              ))}
            </MenuList>
          </InfiniteScroll>
        )}

        {createElement(
          isSpeechCraftMFE ? SpeechcraftGiveFeedback : GiveFeedback,
          {
            modalOpen: giveModalOpen,
            handleClose: handleCloseGiveModal,
            handleOpen: handleOpenGiveModal,
            isRequestedFeedback: isRequestedFeedback,
            feedbackData: isEditModlaOpen ? feedbackToUpdate : feedbackRequest,
            handleRequestedFeedback: handleRequestedFeedback,
            isEditModal: isEditModlaOpen,
            resetEditModal: resetEditModal,
            selectedMembers: feedbackToUpdate?.receiver
              ? [feedbackToUpdate?.receiver]
              : [],
            ...(!isSpeechCraftMFE && {
              selectedClub: feedbackToUpdate?.club?.id || '',
              selectedClubName: feedbackToUpdate?.club?.name,
            }),
            ...(isSpeechCraftMFE && {
              slectedEvent: feedbackToUpdate?.event,
            }),
          },
        )}

        {denyDetailsModalOpen ? (
          <DenyDetailsModal
            title='Denial Details'
            handleClose={toggleDenyDetailsModal}
            reason={feedback?.reason}
            comment={feedback?.comment}
          />
        ) : null}
      </CustomPaper>
    </Container>
  );
};

export default Notifications;
