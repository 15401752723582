import React, { FC, useRef, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  useHistory,
  useParams,
  Link,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import dayjs, { Dayjs } from 'dayjs';
import { FormikHelpers, useFormik } from 'formik';
import parse, { domToReact } from 'html-react-parser';
import { first, isEmpty, size, trim, values } from 'lodash';
// @ts-ignore
import { getAuthenticatedUser } from '@edx/frontend-platform/auth';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, Grid, List, ListItem, Typography, Stack } from '@mui/material';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';

import Button from 'elements/Button';
import Loader from 'elements/Loader';
import DatePicker from 'elements/DatePicker';
import TextField from 'elements/Form/TextField';
import SectionHeader from 'components/SectionHeader';
import { StyledLabel as FormControlLabel } from 'elements/FormControlLabel/style';
import Confirmation from 'components/Confirmation';
import DropdownField from 'elements/DropdownField';
import {
  useGetClubsQuery,
  useGetClubMembersQuery,
} from 'pages/Dashboard/components/Feedback/feedbackApi';

import {
  REACT_APP_PATHWAYS_LOGO_URL,
  REACT_APP_SPEECHCRAFT_LOGO_URL,
} from 'config';

import {
  useGetEvaluationFormQuery,
  useGetSubmittedEvaluationFormQuery,
  useGetEvaluationFormRequestQuery,
  usePostEvaluationFormMutation,
  usePostEvaluationFeedbackFormMutation,
} from 'api/evaluationFeedbackApi';
import { useAppContext } from 'contexts/AppContext';
import { useIsSpeechCraftMFE, useValidations } from 'hooks';
import { useGetEventAttendeesQuery } from 'api/speechCraftApi';

import Questions from './Questions';
import EvaluationCriteria from './EvaluationCriteria';
import { getValidatioinSchema, getInitialFormValues } from './constants';
import {
  Container,
  NavBreadCrumbs,
  FormWrapperOuter,
  FormWrapper,
  PrintIconHolder,
  BrandLogos,
  ButtonsContainer
} from './style';
import { getPlatformName, getUserName, parseDate } from 'utils/utility';
import { HOC } from 'elements/Modal';
import classNames from 'classnames';
import ExpiredRequest from 'components/ExpiredRequest';
import useScrollToError from 'hooks/useScrolltoError';
import Alert from 'elements/Alert';
import AutoComplete from 'elements/AutoComplete';

const renderBackButton = (
  <div className='back-btn'>
    <ArrowBackIosIcon />
    Back
  </div>
);

const ConfirmModal = HOC(Confirmation);
const ExpiredRequestModal = HOC(ExpiredRequest);

const EvaluationDigitalForm: FC<any> = () => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isExpiredRequestModalOpen, setIsExpiredRequestModalOpen] = useState(false);

  const history = useHistory();
  const { id } = useParams<any>();
  const componentRef = useRef<any>();
  const { search, state: routerState = {} } = useLocation<any>();
  const { event } = useAppContext();
  const isSpeechCraftMFE = useIsSpeechCraftMFE();
  const user = getAuthenticatedUser();
  const [clubId, setClubId] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const params = new URLSearchParams(search);
  const formType: any = params.get('type');
  const formStatus: any = params.get('status');
  const isSubmittedForm = formType === 'submitted';
  const isRequestedForm = formType === 'requested';
  const isFeedbackForm = formType === 'feedback';
  const isDraftForm = formStatus === 'draft';
  const isEmptyForm = formType !== 'submitted' && formType !== 'requested';
  const isReadonlyMode =
    isSubmittedForm ||
    (isFeedbackForm && !isEmpty(routerState) ? false : isEmptyForm);

  const { data: clubData = [], isFetching: isFetchingClubs } = useGetClubsQuery(
    {skip:  (routerState?.clubId !== '' && isFeedbackForm) || (isSpeechCraftMFE || !isFeedbackForm)},
  );

  const { isLoading: isFormLoading, data: evaluationForm = {} } =
    useGetEvaluationFormQuery(id, { skip: !id || !isEmptyForm });

  const {
    isLoading: isSubmittedFormLoading,
    data: submittedEvaluationForm = {},
  } = useGetSubmittedEvaluationFormQuery(id, { skip: !id || !isSubmittedForm });

  const { isLoading: isFormRequestLoading, data: requestedFormRequest = {}, error }: any =
    useGetEvaluationFormRequestQuery(id, { skip: !id || !isRequestedForm });

  const { data: clubMembers = [], isFetching: isFetchingMembers, isSuccess: isSuccessMembers, } =
    useGetClubMembersQuery(
      { clubId: routerState?.clubId || clubId },
      { skip: clubId === '' || !isFeedbackForm || isSpeechCraftMFE },
    );

  const { data: eventMembersData, isFetching: isFetchingEventMembers } =
    useGetEventAttendeesQuery(
      { eventId: event?.id, attendeesType: 'all' },
      { skip: !event?.id || !isSpeechCraftMFE || !isFeedbackForm },
    );

  const [submitEvaluationForm] = usePostEvaluationFormMutation();

  const [submitEvaluationFeedbackForm] =
    usePostEvaluationFeedbackFormMutation();

  const formData = isEmptyForm
    ? evaluationForm
    : isSubmittedForm
    ? submittedEvaluationForm
    : requestedFormRequest;

  const formTitle = isEmptyForm
    ? formData?.title
    : formData?.evaluationForm?.title;

  const initialFormValues: any = React.useMemo(
    () =>
      getInitialFormValues(formData, isEmptyForm, isFeedbackForm, routerState, user, isSpeechCraftMFE),
    [formData, isEmptyForm, isFeedbackForm, routerState, user, isSpeechCraftMFE],
  );

  const clubOptions = clubData.reduce(
    (acc: any, { name, id }: any) => [
      ...acc,
      {
        label: name,
        value: id,
      },
    ],
    [],
  );

  const clubMembersOptions = (
    isSpeechCraftMFE ? eventMembersData?.attendees || [] : clubMembers
  ).reduce((acc: any, member: any) => {
    if (member.id === user.userId) {
      return acc;
    }

    if(!clubId && !isSpeechCraftMFE) {
      return [];
    }

    return [
      ...acc,
      {
        [isFeedbackForm ? 'name' : 'name']: getUserName(member),
        [isFeedbackForm ? 'id' : 'id']: member.id,
      },
    ];
  }, []);

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: getValidatioinSchema(
      initialFormValues?.questions,
      isFeedbackForm,
      clubData,
      isSpeechCraftMFE
    ),
    enableReinitialize: true,
    onSubmit: (values: any, { setSubmitting }: FormikHelpers<any>) => {
      handleSubmit(values, setSubmitting);
    },
  });

  useEffect(() => {
    if(error?.data?.detail.includes("Not found") && size(requestedFormRequest) < 1) {
      setIsExpiredRequestModalOpen(true)
    }
  }, [error]);

  useEffect(() => {
    const handleUnloadEvent = (event: any) => {
      if (!isFeedbackForm) {
        return;
      }

      // For IE and Firefox prior to version 4
      if (event) {
        event.returnValue = 'Sure?';
      }

      // For Safari
      return 'Sure?';
    };
    window.addEventListener('beforeunload', handleUnloadEvent);

    return () => window.removeEventListener('beforeunload', handleUnloadEvent);
  }, [isFeedbackForm]);

  const {
    setFieldValue,
    values: formikValues,
    dirty,
    setFieldTouched,
    touched,
    errors,
    isValid,
    isSubmitting,
    submitCount
  }: any = formik;
  useScrollToError(formik);

  const handleActionButton = () => {
    if ((!dirty && !isFeedbackForm && isDraftForm) || (!dirty && !isDraftForm) ) {
      history.goBack();
      return;
    }

    setIsConfirmModalOpen(true);
  };

  const handleConfirmation = () => {
    history.push(
      `${getPlatformName()}/${
        isRequestedForm ? 'requests' : 'feedback/evaluation-feedback'
      }`,
    );
  };

  const handleExpiredRequest = () => {
    history.push(`${getPlatformName()}`);
  };

  const handlePrint: any = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleUpdateField = (event: any) => {
    if (isReadonlyMode) {
      return;
    }
    const { name, value } = event.target;
    const isRequestField = name === 'request';
    const updatedValue = size(trim(value)) > 0 ? value : '';
    setFieldValue(name, isRequestField ? updatedValue : value);
    setFieldTouched(name, true, false);

    // if (name === 'clubId') {
    //   setClubId(value);

    //   setFieldValue('member', isFeedbackForm ? '' : [], false);
    //   setFieldTouched('member', false, false);
    //   setFieldTouched(name, false, false);
    // }
  };

  const handleChangeClub = (value: any) => {
    setFieldValue('member', isFeedbackForm ? '' : [], false);
    setFieldValue('clubId', value);
    if(!value?.id) {
      setClubId('');
      setFieldTouched('clubId', true, false);
    } else {
      setClubId(value?.id);
      setFieldTouched('clubId', false, false);
    }
  };


  const setClubValue = (clubId: any) => {
    setFieldValue('clubId', clubId);
    setFieldTouched('clubId', false, false);
  };

  useEffect(() => {
    if(size(clubOptions) > 0 && size(clubOptions) <= 1 && !formikValues.clubId && !isSpeechCraftMFE && isFeedbackForm) {
      const selectedClub:any = first(clubOptions);
      setClubId(selectedClub.value);
      if(formikValues.member) {
        setClubValue(selectedClub.value)
      }
    }
  }, [clubOptions, formikValues, isSpeechCraftMFE, isFeedbackForm])

  useEffect(() => {
    if(routerState?.clubId && !isSpeechCraftMFE && isFeedbackForm) {
      setClubId(routerState?.clubId);
      setClubValue(routerState?.clubId)
    }
  }, [routerState, isSpeechCraftMFE, isFeedbackForm]);

  const handleUpdateDate = (newValue: Dayjs | null) => {
    setFieldValue('date', dayjs(newValue).format('YYYY-MM-DD'));
    setFieldTouched('date', true, false);
  };

  const handleUpdateQuestion = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldType: string,
  ) => {
    if (isReadonlyMode) {
      return;
    }

    const { name, value } = event.target;
    const updatedQuestions = {
      ...formikValues.questions,
      [name]: { ...formikValues.questions[name], [fieldType]: value },
    };
    setFieldValue('questions', updatedQuestions);
  };

  const getMemberName = () => {
    const data = isSpeechCraftMFE
      ? eventMembersData?.attendees || []
      : clubMembers;

    const member = data.find((m: any) => formikValues.member.id === m.id) || {};

    return getUserName(member);
  };

  const handleSubmit = async (values: any, setSubmitting: any) => {
    setSubmitting(true);

    let selectedClud:any = null;
    if(isFeedbackForm) {
      selectedClud = first(clubData.filter((club: any) => club.id === clubId));
    }
    
    const dataToUpdate = {
      request: id,
      excelled: values.excelled,
      workOn: values.workOn,
      challenge: values.challenge,
      submittedAt: values.date,
      answers: Object.values(values.questions).reduce(
        (acc: any, question: any) => ({
          ...acc,
          [question.id]: { answer: question.answer, comment: question.comment },
        }),
        {},
      ),
      ...(isFeedbackForm && {
        learner: values.member.id,
        speechTitle: values.speechTitle,
        speechDate: parseDate(values.date).toISOString(),
        evaluator: user.userId,
        evaluation_form: id,
      }),
      ...(isSpeechCraftMFE && {
        event: event?.id,
        club: null,
      }),
      ...(!isSpeechCraftMFE && {
        event: null,
        club: selectedClud?.uuid,
      }),
    };

    try {
      if (isFeedbackForm) {
        await submitEvaluationFeedbackForm(dataToUpdate).unwrap();
        toast.success(
          `Evaluation has been successfully shared with ${getMemberName()}!`,
        );
        history.push(`${getPlatformName()}/feedback/evaluation-feedback`);
        return;
      }

      await submitEvaluationForm(dataToUpdate).unwrap();
      toast.success(
        `Evaluation has been successfully shared with ${formikValues.member ? formikValues.member : formikValues.member.name}!`,
      );
      history.push(`${getPlatformName()}/requests`);
    } catch (e: any) {
      toast.error(
        e?.data || 'Oops, an error occurred. Please try again later.',
      );
    } finally {
      setSubmitting(false);
    }
  };

  const showSubmitButton =
    (isFeedbackForm && !isEmpty(routerState)) ||
    (formData?.isSubmitted !== undefined && !formData.isSubmitted);

  const isLoading =
    isFormLoading || isSubmittedFormLoading || isFormRequestLoading;

  const options = {
    replace: ({ name, attribs, children }: any) => {
      if (attribs && name === 'ul') {
        return (
          <List className='flex-start'>{domToReact(children, options)}</List>
        );
      }

      if (attribs && name === 'li') {
        return (
          <ListItem>
            <ArrowRightRoundedIcon />
            {domToReact(children, options)}
          </ListItem>
        );
      }
    },
  };

  if (!id || (isFeedbackForm && isEmpty(routerState))) {
    return (
      <Redirect to={`${getPlatformName()}/feedback/evaluation-feedback`} />
    );
  }

  return (
    <Container>
      <div role="navigation" aria-label="breadcrumbs">
        <NavBreadCrumbs>
          <li>
            <Link to={`${getPlatformName()}/feedback`}>Feedback</Link>
          </li>
          <li>
            <span className='separator'>/</span>
          </li>
          <li>Digital Evaluation Forms</li>
        </NavBreadCrumbs>
      </div>
      {isLoading ? (
        <Loader position='relative' />
      ) : (
        <>
          <SectionHeader
            title={formTitle}
            buttonText={renderBackButton}
            buttonColor='primary'
            handleActionButton={handleActionButton}
          />
          <FormWrapperOuter>
            <PrintIconHolder
              aria-label='Print'
              className='reset-btn'
              onClick={handlePrint}
            >
              <PrintRoundedIcon />
            </PrintIconHolder>

            <FormWrapper ref={componentRef}>
              <BrandLogos>
                <List>
                  <ListItem>
                    <img
                      src={process.env.REACT_APP_FAVICON_URL}
                      alt='Toastmasters'
                    />
                  </ListItem>
                  <ListItem className='platform-logo'>
                    <img
                      src={
                        isSpeechCraftMFE
                          ? REACT_APP_SPEECHCRAFT_LOGO_URL
                          : REACT_APP_PATHWAYS_LOGO_URL
                      }
                      alt={`${isSpeechCraftMFE ? 'Speechcraft' : 'Pathways'}`}
                    />
                  </ListItem>
                </List>
              </BrandLogos>
              {showAlert && !isValid &&
                <>
                  <Alert type='error' onClose={() => setShowAlert(false)}>
                    <strong>
                    All items below must be completed to submit the Evaluation Form.
                    </strong>
                  </Alert>
                  <br/>
                </>
              }
              
              <Typography variant='h1' className='fw-700' pb={2}>
                Evaluation Form
              </Typography>
              <Typography variant='h2' className='fw-500'>
                {formTitle}
              </Typography>
              <Box py={5}>
                <Grid container spacing={5}>
                {!isSpeechCraftMFE && clubData?.length > 1 && isFeedbackForm && (
                  <Grid item xs={12} md={8} className='inline-label-control'>
                      <FormControlLabel
                        label='Club'
                        labelPlacement='start'
                        id='clubId'
                        control={
                          <AutoComplete
                            id='select-club-id'
                            options={clubData || []}
                            loading={isFetchingClubs}
                            loadingText='Loading Club'
                            defaultValue={formikValues.clubId}
                            getOptionLabel={(option: any) => option.name}
                            selectedOptions={handleChangeClub}
                            placeholder='Select a club'
                            name='clubId'
                            className={`${formikValues.clubId ? 'has-data' : ''}`}
                            helperText={touched['clubId'] && (errors['clubId']?.name || errors['clubId'])}
                            error={!!touched['clubId'] && (!!errors['clubId']?.name || !!errors['clubId'])}
                          />
                        }
                      />
                  </Grid>
                )}
                  <Grid item xs={12} md={8} className='inline-label-control'>
                    <FormControlLabel
                      label={isSpeechCraftMFE ? 'Name' : 'Member Name'}
                      labelPlacement='start'
                      control={
                        isFeedbackForm ? (
                          <AutoComplete
                            id='select-club-members'
                            options={clubMembersOptions || []}
                            loading={isFetchingMembers || isFetchingEventMembers}
                            loadingText={`Loading ${
                              isSpeechCraftMFE ? 'Speechcrafters' : 'Member'
                            }`}
                            defaultValue={formikValues.member}
                            getOptionLabel={(option: any) => option.name}
                            selectedOptions={(e: any) => {
                              setFieldValue('member', e);
                              setFieldTouched('member', true, false);
                            }}
                            placeholder={`Select ${
                              isSpeechCraftMFE ? 'speechcrafter' : 'member'
                            }`}
                            name='member'
                            className={`${formikValues.member ? 'has-data' : ''}`}
                            helperText={touched['member'] && (errors['member']?.name || errors['member'])}
                            error={!!touched['member'] && (!!errors['member']?.name || !!errors['member'])}
                          />
                        ) : (
                          <TextField
                            fullWidth
                            value={formikValues.member}
                            name='member'
                            disabled
                          />
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControlLabel
                      label='Date'
                      className='w-auto'
                      labelPlacement='start'
                      control={
                        <DatePicker
                          label='Date'
                          value={formikValues.date || null}
                          handleChange={handleUpdateDate}
                          name='date'
                          className={`primary-color-icon ${
                            formikValues.date ? 'has-data' : ''
                          }`}
                          helperText={touched['date'] && errors['date']}
                          error={!!touched['date'] && !!errors['date']}
                          disabled={isReadonlyMode || !isFeedbackForm}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={8} className='inline-label-control'>
                    <FormControlLabel
                      label='Evaluator'
                      labelPlacement='start'
                      control={
                        <TextField
                          className={classNames({
                            'evaluator-field': formikValues.evaluator !== '',
                          })}
                          fullWidth
                          name='evaluator'
                          value={formikValues.evaluator}
                          disabled
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography mt={2}>
                      <Typography className='grey-text' component='strong'>
                        Speech Length
                      </Typography>{' '}
                      {formikValues.length}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className='inline-label-control'>
                    <FormControlLabel
                      label='Speech Title'
                      labelPlacement='start'
                      control={
                        <TextField
                          fullWidth
                          id='speech-title'
                          name='speechTitle'
                          value={formikValues.speechTitle}
                          onChange={handleUpdateField}
                          helperText={
                            touched['speechTitle'] && errors['speechTitle']
                          }
                          error={
                            !!touched['speechTitle'] && !!errors['speechTitle']
                          }
                          disabled={isReadonlyMode || !isFeedbackForm}
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box py={5}>
                <Typography variant='h4'>Purpose Statement</Typography>
                {formikValues.purposeStatement &&
                  parse(formikValues.purposeStatement, options)}
                <Typography variant='h4'>Notes for the Evaluator</Typography>
                {formikValues.notes && parse(formikValues.notes, options)}
                {isRequestedForm && (
                  <Box>
                    <Typography variant='h4' pt={4} pb={2}>
                      Feedback Request from {formikValues.member}
                    </Typography>
                    <Typography>{formikValues.description}</Typography>
                  </Box>
                )}
                <Typography variant='h4' pt={4} pb={2}>
                  General Comments
                </Typography>
                <Box pb={4} className='page-break'>
                  <FormControlLabel
                    label='You excelled at:'
                    labelPlacement='top'
                    id='excelled'
                    control={
                      <TextField
                        multiline
                        fullWidth
                        name='excelled'
                        value={formikValues.excelled}
                        onChange={handleUpdateField}
                        helperText={touched['excelled'] && errors['excelled']}
                        error={!!touched['excelled'] && !!errors['excelled']}
                        disabled={isReadonlyMode}
                        inputProps={{'aria-labelledby': 'excelled'}}
                      />
                    }
                  />
                </Box>
                <Box pb={4} className='page-break'>
                  <FormControlLabel
                    label='You may want to work on:'
                    labelPlacement='top'
                    id='want_work'
                    control={
                      <TextField
                        multiline
                        fullWidth
                        name='workOn'
                        value={formikValues.workOn}
                        onChange={handleUpdateField}
                        helperText={touched['workOn'] && errors['workOn']}
                        error={!!touched['workOn'] && !!errors['workOn']}
                        disabled={isReadonlyMode}
                        inputProps={{'aria-labelledby': 'want_work'}}
                      />
                    }
                  />
                </Box>
                <Box className='page-break'>
                  <FormControlLabel
                    label='To challenge yourself:'
                    labelPlacement='top'
                    id='challenge'
                    control={
                      <TextField
                        multiline
                        fullWidth
                        name='challenge'
                        value={formikValues.challenge}
                        onChange={handleUpdateField}
                        helperText={touched['challenge'] && errors['challenge']}
                        error={!!touched['challenge'] && !!errors['challenge']}
                        disabled={isReadonlyMode}
                        inputProps={{'aria-labelledby': 'challenge'}}
                      />
                    }
                  />
                </Box>
                <Questions
                  questions={formikValues.questions}
                  handleUpdateQuestion={handleUpdateQuestion}
                  isReadonlyMode={isReadonlyMode}
                  errors={errors?.questions}
                  touched={touched?.questions}
                />
                <EvaluationCriteria questions={formikValues.questions} />
              </Box>
              
              <Box pt={10}>
                <Typography variant='body2'>
                  © 2016 Toastmasters International. All rights reserved.
                  Toastmasters International, the Toastmasters International
                  logo and all other Toastmasters International trademarks and
                  copyrights are the sole property of Toastmasters International
                  and may be used only by permission. Item 8205E Rev. 12/2022
                </Typography>
              </Box>

              {showSubmitButton && (
                <ButtonsContainer>
                  <Button variant='outlined' onClick={handleActionButton} className="btn-back"><ArrowBackIosIcon fontSize='small' /> Back</Button>
                  <Button
                    variant='contained'
                    onClick={async (e: any) => {
                      e.preventDefault();
                      const errors = await formik.validateForm();
                      
                      if (Object.keys(errors).length > 0) {
                        setShowAlert(true)
                        const markAllFieldsTouched = (values: any) => {
                          if (typeof values !== "object" || values === null) return true;
                          return Object.keys(values).reduce((acc: any, key) => {
                            acc[key] = markAllFieldsTouched(values[key]);
                            return acc;
                          }, {});
                        };
                    
                        const allTouched = markAllFieldsTouched(formik.values);
                        formik.setTouched(allTouched);

                        if(document && document.getElementById('scrollableDiv')){
                          document?.getElementById('scrollableDiv')?.scrollTo(0, 0);
                          
                        }
                      } else {
                        formik.handleSubmit();
                      }
                    }}
                    disabled={isSubmitting}
                    className='w-75'
                  >
                    Share Evaluation
                  </Button>
                </ButtonsContainer>
              )}
            </FormWrapper>
          </FormWrapperOuter>
        </>
      )}
      {isConfirmModalOpen && (
        <ConfirmModal
          handleClose={() => setIsConfirmModalOpen(false)}
          title='Confirmation'
          buttonText='Continue'
          cancelText='Stay on page'
          showLoader={false}
          handleConfirmation={handleConfirmation}
        >
          If you continue, you will lose all text entered on this page.
        </ConfirmModal>
      )}


      {isExpiredRequestModalOpen && (
        <ExpiredRequestModal
          handleClose={() => {}}
          title='Expired Request'
          buttonText='Go to Learning Dashboard'
          showLoader={false}
          showCloseIcon={false}
          handleConfirmation={handleExpiredRequest}
        >
          This action is no longer available. Please contact <a href="mailto:educationprogram@toastmasters.org">educationprogram@toastmasters.org</a> if you have any questions.
        </ExpiredRequestModal>
      )}
    </Container>
  );
};

export default EvaluationDigitalForm;
