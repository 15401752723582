import Box from "@mui/material/Box";
import { styled } from '@mui/material';
import { DeviceDown } from "styles/breakpoints";

export const SkeletonWrapper = styled(Box)`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
`;

export const BadgeItemSkeleton = styled(Box)`
    width: 150px;

    .badge-icon {
        width: 150px;
        height: 150px;
        border-radius: 100%;
        padding: 25px;
        background: ${({ theme }) => theme.palette.ternary.white};
        box-shadow: 0px 2px 8px rgba(0, 0, 41, 0.16);
    }
    .badge-title {
        margin-top: 15px;
    }
`;

export const CardItemSkeleton = styled(Box)`
    max-width: 260px;
    width: 100%;
    background: ${({ theme }) => theme.palette.ternary.white};
    box-shadow: 0px 2px 6px rgba(0, 0, 41, 0.16);
    border-radius: 3px;
    margin: 0 auto 10px;

    .card-image {
        width: 100%;
        height: 155px;
    }

    .card-title {
        padding: 20px;

        .card-title-item {
            @media ${DeviceDown.sm} {
                width: 100% !important;
            }
        }
        
    }
`;

export const TextBlockSkeleton = styled(Box)`
    height: 150px;
    width: 100%;
`;