import { Box, styled } from '@mui/material';
import { theme } from 'styles/theme';
import { DeviceUp, DeviceDown } from 'styles/breakpoints';

export const Container = styled(Box)`
  max-width: 1110px;
  width: 100%;
  padding: 0 15px;
  margin: 30px auto 0;

  & h2 {
    margin-bottom: 10px;
  }

  & .infinite-scroll-component {
    padding: 10px 5px;
  }

  & .back-btn {
    display: flex;
    align-items: center;

    svg {
      width: 14px;
      margin-right: 8px;
    }
  }
`;

export const NavBreadCrumbs = styled('ul')`
  padding: 0;
  margin: 10px 0 20px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;

  & li {
    padding: 2px 10px 2px 0;
    font-weight: 700;
    font-size: 14px;

    &:last-child {
      padding-right: 0;
    }

    & a {
      color: ${({ theme }) => theme.palette.secondary.main};
    }
  }
`;

export const PrintIconHolder = styled('button')`
  position: absolute;
  right: 26px;
  top: 26px;
  color: ${({ theme }) => theme.palette.secondary.main};
  z-index: 2;
`;

export const BrandLogos = styled('div')`
  .MuiList-root {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 30px;

    @media ${DeviceUp.sm} {
      padding-bottom: 60px;
    }

    & .MuiListItem-root {
      display: inline-flex;
      width: auto;
      border-left: 1px solid #9ea9a8;
      padding: 5px 25px;

      & img {
        max-width: 100px;
        object-fit: contain;

        @media ${DeviceUp.sm} {
          max-width: 165px;
        }
      }

      &:first-child {
        padding-left: 0;
        border: none;
      }
    }
  }

  .platform-logo {
    img {
      height: 100px;
      width: 100%;
    }
  }
`;
export const FormWrapperOuter = styled('div')`
  max-width: 1080px;
  width: 100%;
  margin: 10px auto 30px;
  position: relative;
`;

export const FormWrapper = styled('div')`
  width: 100%;
  padding: 20px;
  background: ${({ theme }) => theme.palette.ternary.white};
  border-radius: 4px;
  position: relative;

  @media ${DeviceUp.sm} {
    padding: 40px;
  }

  & .MuiTypography-h4 {
    font-size: 18px;
    margin-bottom: 5px;
  }

  .Mui-error .MuiOutlinedInput-notchedOutline {
    border-width: 2px !important;
    border-color: ${({ theme }) => theme.palette.error.contrastText} !important;
  }

  .Mui-error {
    font-weight: 700;
    margin: 4px 0 0;
  }

  & p strong {
    font-weight: 700;

    &.grey-text {
      color: ${({ theme }) => theme.palette.ternary.greyShade};
    }
  }

  & .MuiFormControlLabel-root {
    & .MuiFormControlLabel-label {
      &.Mui-disabled {
        color: inherit;
      }
    }

    &.Mui-disabled {
      .evaluator-field {
        .MuiInputBase-root {
          fieldset {
            border-color: transparent;
          }
        }
      }
    }

    & .MuiButtonBase-root {
      & + .Mui-disabled {
        color: rgba(0, 0, 0, 0.38) !important;
      }
      &.Mui-disabled.Mui-checked {
        opacity: 1;

        & + .Mui-disabled {
          color: ${({ theme }) => theme.palette.ternary.greyShade} !important;
        }
      }
    }

    & .MuiFormControl-root {
      .MuiInputBase-root {
        &.Mui-disabled 
          button.Mui-disabled {
            svg {
              color: rgba(0, 0, 0, 0.23);
            }
          }
          fieldset {
            border-width: 1px;
          }
        }

        &:hover {
          fieldset {
            border-width: 2px;
          }
        }

        & .MuiInputBase-input {
          &.Mui-disabled {
            color: ${({ theme }) => theme.palette.ternary.greyShade};
            -webkit-text-fill-color: ${({ theme }) => theme.palette.ternary.greyShade};
          }
        }
      }
    }
  }

  & .inline-label-control {
    .MuiFormControlLabel-label {
      min-width: 120px;
    }
  }
  & .MuiFormControlLabel-labelPlacementStart {
    @media ${DeviceDown.sm} {
      flex-wrap: wrap-reverse;
    }

    .MuiFormControlLabel-label {
      margin-right: 10px;
      font-weight: 700;

      @media ${DeviceDown.md} {
        min-width: 120px;
      }

      @media ${DeviceDown.sm} {
        width: 100%;
        margin-right: 0;
        margin-bottom: 5px;
      }
    }

    .MuiInputBase-root {
      height: 40px;
    }
  }

  & .radio-options {
    padding: 6px 0;
    border-radius: 4px;
    border: 2px solid transparent;

    &.error {
      border-color: ${({ theme }) => theme.palette.error.contrastText} !important;
    }

    label {
      margin-right: 15px;
    }
  }

  & .choices-box {
    padding: 0 0 30px;
  }

  & .criteria-options {
    margin: 30px auto;
    border: 1px solid ${({ theme }) => theme.palette.ternary.greyShade};
    border-radius: 4px;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      padding: 5px 15px;

      li {
        padding: 15px;
      }
    }
  }

  .flex-start li {
    align-items: flex-start;
    padding: 4px 15px;

    svg {
      color: ${({ theme }) => theme.palette.secondary.main};
      margin-right: 5px;
    }
  }

  .list-item {
    display: flex;
  }
  .serial-number {
    min-width: 26px;
  }

  & .column-text {
    column-count: 1;
    column-gap: 60px;

    @media ${DeviceUp.sm} {
      column-count: 2;
    }

    .text-box {
      padding-bottom: 30px;
      page-break-inside: avoid;  
      break-inside: avoid-column;
      display:table;   
    }
  }
`;

export const ErrorMessage = styled(Box)`
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1.66;
  text-align: left;
  margin: 4px 0px 14px 0;
  color: ${({ theme }) => theme.palette.error.main};
`;


export const SelectionBlock = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 80px 0 20px;

  h1 {
    margin: 0 0 50px;
  }

  @media ${DeviceDown.sm} {
    padding: 20px 0;

    h1 {
      margin: 0 0 20px;
    }
  }

  .btn-back {
    svg {
      font-size: 14px;
    }
  }
`;

export const SelectionBoxes = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin: 0 auto 30px;
  width: 100%;
  max-width: 730px;

  @media ${DeviceDown.sm} {
    flex-direction: column;
    margin: 0 auto 20px;
  }
`;

export const Block = styled(Box)`
  border: 1px solid #e8e8e8;
  padding: 30px 20px;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.25);
  width: calc(50% - 15px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 10px;

  h2 {
    margin: 0 0 20px;
  }

  @media ${DeviceDown.md} {
    width: calc(50% - 10px);
    padding: 20px 15px;

    h2 {
      font-size: 1.1em;
    }
  }

  @media ${DeviceDown.sm} {
    width: 100%;
  }

  .holder {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    align-items: center;

    button {
      min-width: 140px;
    }
  }

`;

export const IconHolder = styled(Box)`
  border-radius: 100%;
  border: 1px solid #c1c1c0;
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px;
`;

export const SelectionBottomBlock = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    color: #4d4d4d;
    margin: 0 0 40px;

    a {
      color: #006094;
    }
  }

  @media ${DeviceDown.sm} {
    margin: 0 0 25px;
  }

  button {
    min-width: 140px;
  }
`;

export const RequestFormHolder = styled(Box)`
  max-width: 550px;
  margin: 0 auto 20px;
  
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px 0;
  }
`;

export const ButtonsContainer = styled(Box)`
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  padding: 25px 10px 5px;
  
  @media ${DeviceUp.sm} {
    position: fixed;
    background-color: white;
    padding: 20px 10px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }

  .btn-back {
    min-width: 120px;

    svg {
      font-size: 14px;
    }
  }
`;