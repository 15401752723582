import React, { useEffect, useState } from 'react';
import map from 'lodash/map';
import Cookies from 'universal-cookie';
import { Route, Switch, Redirect } from 'react-router-dom';
import dashboardLayout from 'Layouts/Dashboard';
import publicLayout from 'Layouts/Public';
import Dashboard from 'pages/Dashboard';
import CourseList from 'pages/CourseList';
import SharedBadge from 'pages/SharedBadge';
import EvaluationDigitalForm from 'pages/EvaluationDigitalForm/FormView';
import Requests from 'pages/Requests';
import Search from 'pages/Search';
import SearchMembers from 'pages/Search/Components/SearchMembers';
import SearchResources from 'pages/Search/Components/SearchResources';
import MyClubs from 'pages/MyClubs';
import ClubDetails from 'pages/MyClubs/Components/ClubDetails';
import Notifications from 'pages/Notifications';
import FeedbackNotification from 'pages/Notifications/NotificationDetailPages/Feedback';
import ResourcesNotification from 'pages/Notifications/NotificationDetailPages/Resources';
import RequestNotification from 'pages/Notifications/NotificationDetailPages/Request';
import UserProfile from 'pages/Profile';
import SpeechCertificates from 'pages/SpeechCertificates';
import SpeechDashboard from 'pages/SpeechDashboard';
import SpeechValidation from 'pages/SpeechValidation';
import MyCohort from 'pages/MyCohort';
import AssessmentForm from 'pages/AssessmentForm';
import errorLayout from 'Layouts/404';
import Error from 'pages/Error';
import PrivateRoute from 'components/PrivateRoute';
import { Role } from 'config';

import SpeedIcon from '@mui/icons-material/Speed';
import { PUBLIC_PATH, TOASTMASTER_AUTH_URL, REQUEST_TYPES } from 'config';
import axiosClient from 'utils/client';
import { getPlatformName } from 'utils/utility';
import { useAppContext } from 'contexts/AppContext';
import Loader from 'elements/Loader';
import BcmDashboard from 'pages/BcmDashboard';
import { useIsSpeechCraftMFE } from 'hooks';
import EvaluationFormSelectionSection from 'pages/EvaluationDigitalForm';

export const initializeSession = async () => {
  const cookies = new Cookies();
  const resp = await axiosClient({
    url: `${TOASTMASTER_AUTH_URL}`,
    method: 'GET',
  });
  cookies.remove('csrftoken');
  cookies.set('csrftoken', resp?.data.csrfToken, { path: '/' });
  return resp?.data.csrfToken;
};

export const RoutesHOC = (routes: any, defaultPath: any) => {
  const HOC = (props: any) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const cookies = new Cookies();
    const csrftoken = cookies.get('csrftoken');
    const isSpeechCraftMFE = useIsSpeechCraftMFE();

    useEffect(() => {
      const fetchSession = async () => {
        if (!csrftoken) {
          const data = await initializeSession();
          setIsLoaded(true);
        } else {
          setIsLoaded(true);
        }
      };
      fetchSession();
    }, [csrftoken]);

    const { isReleasingHijackUser } = useAppContext();

    if (!isLoaded || isReleasingHijackUser) {
      return <Loader position='absolute' background='transparent' />;
    }

    return (
      <>
        <Switch>
          {map(routes, (route: any) => {
            const isRequestPath =
              route.path === DashboardRoutes.REQUESTS.path && isSpeechCraftMFE;
            const componentProps = {
              ...(isRequestPath && { requestType: REQUEST_TYPES.EVALUATION }),
            };

            return (
              <PrivateRoute
                key={route.name}
                path={`${route.path}`}
                render={() => <route.component {...componentProps} />}
                roles={route.roles}
                ispublic={route.ispublic}
                {...route.routeProps}
              />
            );
          })}
          <Redirect to={defaultPath} />
        </Switch>
      </>
    );
  };

  return HOC;
};

export const DashboardRoutes = {
  COURSE_LIST: {
    name: 'CourseList',
    path: `${getPlatformName()}/courseList/:category`,
    icon: <SpeedIcon />,
    isDisabled: true,
    component: CourseList,
  },
  NOTIFICATIONS: {
    name: 'Notifications',
    path: `${getPlatformName()}/notifications/:slug?`,
    icon: <SpeedIcon />,
    isDisabled: true,
    component: Notifications,
    routeProps: { exact: true },
  },
  FEEDBACK_NOTIFICATION: {
    name: 'Notifications',
    path: `${getPlatformName()}/notifications/feedback/:slug`,
    icon: <SpeedIcon />,
    isDisabled: true,
    component: FeedbackNotification,
  },
  SPEECH_VALIDATION: {
    name: 'SpeechValidation',
    path: `${getPlatformName()}/speech-validation/:speechId?`,
    icon: <SpeedIcon />,
    isDisabled: true,
    component: SpeechValidation,
    roles: [Role.coordinator],
  },
  SPEECH_CERTIFICATES: {
    name: 'SpeechCertificates',
    path: `${getPlatformName()}/certificates`,
    icon: <SpeedIcon />,
    isDisabled: false,
    component: SpeechCertificates,
    roles: [Role.coordinator],
  },
  // REQUEST_NOTIFICATION: {
  //   name: 'Notifications',
  //   path: `${getPlatformName()}/notifications/request/:slug`,
  //   icon: <SpeedIcon />,
  //   isDisabled: true,
  //   component: RequestNotification,
  // },
  RESOURCES_NOTIFICATION: {
    name: 'Notifications',
    path: `${getPlatformName()}/notifications/resources/:slug`,
    icon: <SpeedIcon />,
    isDisabled: true,
    component: ResourcesNotification,
  },
  RESOURCES_DETAIL: {
    name: 'ResourcesDetail',
    path: `${getPlatformName()}/resources/resources-details/:assetUuid`,
    icon: <SpeedIcon />,
    isDisabled: true,
    component: ResourcesNotification,
  },
  EVALUATION_FORM_SELECTION: {
    name: 'DigitalEvaluationFormSelection',
    path: `${getPlatformName()}/feedback/evaluation-form/:id/:title`,
    icon: <SpeedIcon />,
    isDisabled: true,
    exact: true,
    component: EvaluationFormSelectionSection,
  },
  DIGITAT_EVALUATION_FORM: {
    name: 'DigitalEvaluationForm',
    path: `${getPlatformName()}/feedback/evaluation-form/:id`,
    icon: <SpeedIcon />,
    isDisabled: true,
    exact: true,
    component: EvaluationDigitalForm,
  },
  REQUESTS: {
    name: 'request',
    path: `${getPlatformName()}/requests/:notificationId?`,
    icon: <SpeedIcon />,
    isDisabled: true,
    component: Requests,
  },
  SEARCH_MEMBERS: {
    name: 'search',
    path: `${getPlatformName()}/search/members`,
    icon: <SpeedIcon />,
    isDisabled: true,
    component: SearchMembers,
  },
  SEARCH_RESOURCES_DETAILS: {
    name: 'search',
    path: `${getPlatformName()}/search/resources/details/:assetUuid`,
    icon: <SpeedIcon />,
    isDisabled: true,
    component: ResourcesNotification,
  },
  SEARCH_RESOURCES: {
    name: 'search',
    path: `${getPlatformName()}/search/resources`,
    icon: <SpeedIcon />,
    isDisabled: true,
    component: SearchResources,
  },
  SEARCH: {
    name: 'search',
    path: `/dashboard/search`,
    icon: <SpeedIcon />,
    isDisabled: true,
    component: Search,
  },
  PROFILE: {
    name: 'club',
    path: `${getPlatformName()}/my-clubs/profile/:clubId/:username/:activeSection?`,
    icon: <SpeedIcon />,
    isDisabled: false,
    component: UserProfile,
  },
  COORDINATOR_PROFILE_ASSESSMENT_FORM: {
    name: 'Assessment',
    path: `${getPlatformName()}/my-cohort/profile/:username/assessment-form/:formId/:formTitle/:progress`,
    icon: <SpeedIcon />,
    isDisabled: true,
    component: AssessmentForm,
    roles: [Role.coordinator],
  },
  SPEECHCRAFTER_PROFILE: {
    name: 'speechcrafter profile',
    path: `${getPlatformName()}/my-cohort/profile/:username/:activeSection?/:activeSubSection?`,
    icon: <SpeedIcon />,
    isDisabled: false,
    component: UserProfile,
    roles: [Role.coordinator, Role.speechcrafter],
  },
  CLUB_DETAILS: {
    name: 'club',
    path: `${getPlatformName()}/my-clubs/:clubId`,
    icon: <SpeedIcon />,
    isDisabled: false,
    component: ClubDetails,
    exact: true,
  },
  MY_COHORT: {
    name: 'my cohort',
    path: `${getPlatformName()}/my-cohort`,
    icon: <SpeedIcon />,
    isDisabled: false,
    component: MyCohort,
    roles: [Role.coordinator, Role.speechcrafter],
  },
  CLUBS: {
    name: 'club',
    path: `${getPlatformName()}/my-clubs`,
    icon: <SpeedIcon />,
    isDisabled: false,
    component: MyClubs,
  },
  PROFILE_ASSESSMENT_FORM: {
    name: 'Assessment',
    path: `${getPlatformName()}/profile/assessment-form/:formId/:formTitle/:progress`,
    icon: <SpeedIcon />,
    isDisabled: true,
    component: AssessmentForm,
    roles: [Role.speechcrafter],
  },
  MY_PROFILE: {
    name: 'club',
    path: `${getPlatformName()}/profile/:activeSection?/:activeSubSection?`,
    icon: <SpeedIcon />,
    isDisabled: false,
    component: UserProfile,
  },
  SPEECH_DASHBOARD: {
    name: 'Speech Dashboard',
    path: `${getPlatformName()}/speech-dashboard/:activeOption?`,
    icon: <SpeedIcon />,
    isDisabled: true,
    component: SpeechDashboard,
    roles: [Role.coordinator],
  },
  BCM_DASHBOARD: {
    name: 'Bcm Dashboard',
    path: `${getPlatformName()}/bcm-dashboard/:activeOption?/:notificationId?`,
    icon: <SpeedIcon />,
    isDisabled: true,
    component: BcmDashboard,
  },
  ASSESSMENT_FORM: {
    name: 'Assessment',
    path: `${getPlatformName()}/assessment-form/:formId/:formTitle/:progress`,
    icon: <SpeedIcon />,
    isDisabled: true,
    component: AssessmentForm,
    roles: [Role.speechcrafter],
  },
  DASHBOARD: {
    name: 'Dashboard',
    path: `${getPlatformName()}/:activeSection?/:activeSubSection?`,
    icon: <SpeedIcon />,
    isDisabled: true,
    component: Dashboard,
  },
};

export const SocialRoutes = {
  BADGE_SHARE: {
    name: 'badgeShare',
    path: `${getPlatformName()}/public/social/:slug?`,
    icon: <SpeedIcon />,
    isDisabled: true,
    component: SharedBadge,
  },
};

export const ErrorRoutes = {
  ERROR_PAGE: {
    name: 'error',
    path: `${getPlatformName()}/error/:status?/:errorType?`,
    icon: <SpeedIcon />,
    isDisabled: true,
    component: Error,
    ispublic: false,
  },
};

export const AppRoutes = {
  ERROR: {
    path: `${getPlatformName()}/error/:status?/:errorType?`,
    name: 'Error',
    component: errorLayout(
      RoutesHOC(ErrorRoutes, `${getPlatformName()}/error/:status?/:errorType?`),
    ),
  },
  SOCIAL: {
    path: `${getPlatformName()}/public/social/:slug?`,
    name: 'Social',
    component: publicLayout(
      RoutesHOC(SocialRoutes, `${getPlatformName()}/public/social/:slug?`),
    ),
  },
  DASHBOARD: {
    path: `${getPlatformName()}/:activeSection?/:activeSubSection?`,
    name: 'Dashboard',
    component: dashboardLayout(
      RoutesHOC(
        DashboardRoutes,
        `${getPlatformName()}/:activeSection?/:activeSubSection?`,
      ),
    ),
  },
};

export const USER_LANDING_PAGE = AppRoutes.DASHBOARD.path;

export const AppRouter = RoutesHOC(AppRoutes, USER_LANDING_PAGE);
