import { Box, Skeleton } from '@mui/material';
import { memo, FunctionComponent } from 'react';
import { SkeletonWrapper, BadgeItemSkeleton } from '../style';

const BadgeSkeleton: FunctionComponent<any> = () => {
  return (
    <SkeletonWrapper>
      {
        [1, 2, 3, 4, 5].map( (index: any) => (
          <BadgeItemSkeleton key={index}>
            <Box className="badge-icon">
              <Skeleton variant='circular' width="100%" height="100%" />
            </Box>
            <Skeleton className='badge-title' variant='rectangular' height={24} />
          </BadgeItemSkeleton>
        ))
      }
    </SkeletonWrapper>
  );
};

export default memo(BadgeSkeleton);
