import { Box, Grid, Skeleton } from '@mui/material';
import { memo, FunctionComponent } from 'react';
import { TextBlockSkeleton, SkeletonWrapper } from '../style';

const SpeechlogSkeleton: FunctionComponent<any> = () => {
  return (
    <SkeletonWrapper>
      <Grid container spacing={5}>
        {
          [1, 2, 3].map( (index: any) => (
            <Grid item xs={12} key={index}>
            <TextBlockSkeleton>
                <Skeleton variant='rectangular' width="100%" height="100%" />
            </TextBlockSkeleton>
            </Grid>
          ))
        }
      </Grid>
      
    </SkeletonWrapper>
  );
};

export default memo(SpeechlogSkeleton);
