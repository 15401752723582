import {
  useState,
  FunctionComponent,
  useEffect,
  useCallback,
  memo,
} from 'react';
import { Link, useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import { size, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { get } from 'lodash';
import { getPlatformName, getQueryParam } from 'utils/utility';
import compose from 'compose-function';

import Loader from 'elements/Loader';
import FormControlLabel from 'elements/FormControlLabel/FormControlLabel';
import DropdownFieldV2 from 'elements/DropdownFieldV2';
import {
  APPROVALS_SORT_OPTIONS_PATHWAYS,
  REQUESTS_SORT_OPTIONS_PATHWAYS,
  REQUESTS_SORT_OPTIONS_SPEECHCRAFT,
  REQUEST_TYPES,
} from 'config';
import { HOC } from 'elements/Modal';

import { getAllRequestsSelector } from './ducks/selectors';
import { getAllRequests, reserRequests } from './ducks/slice';
import RequestItem from './RequestItem';
import ApprovalRequest from './ApprovalRequest';
import Approve from './Approve';
import Deny from './Deny';
import ConfirmRequest from './ConfirmRequest';
import { selectReviewReqeust, resetRequestReview } from './ducks/approvalSlice';
import {
  Container,
  NavBreadCrumbs,
  SortFieldWrapper,
  FilterWrapper,
  CustomPaper,
} from './style';

import { handleOpenRequestModal } from 'pages/Requests/ducks/approvalSlice';

import { useGetNotificationDetailsQuery } from 'api/notificationApi';
import RequestItemSkeleton from './RequestItemSkeleton';

const ApprovalRequestModal = compose(HOC)(ApprovalRequest);
const ApproveModal = compose(HOC)(Approve);
const DenyModal = compose(HOC)(Deny);

const Requests: FunctionComponent<any> = ({ requestType }) => {
  const { notificationId } = useParams<{ notificationId: string }>() || {};

  const dispatch = useDispatch();

  const [sortType, setSortType] = useState('');

  const reviewRequest = useSelector(selectReviewReqeust);
  const { data, isLoading } = useSelector(getAllRequestsSelector);

  const {
    isFetching: isFetchingNotification,
    data: notificationDate = {},
  }: any = useGetNotificationDetailsQuery(notificationId, {
    skip: !notificationId,
  });

  useEffect(() => {
    if (isEmpty(notificationDate) || isFetchingNotification) {
      return;
    }

    dispatch(handleOpenRequestModal(notificationDate));
  }, [dispatch, isFetchingNotification, notificationDate]);

  const handleUpdateSortType = (event: any) => {
    const { value } = event.target;
    setSortType(value);
  };

  const handleGetNextData = useCallback(() => {
    const next = get(data, 'next', null) || '';

    if (!next) {
      return;
    }

    const { page }: any = getQueryParam(next);
    dispatch(getAllRequests({ page, filter: sortType, type: requestType }));
  }, [data, dispatch, requestType, sortType]);

  const handleModalClose = () => {
    dispatch(resetRequestReview());
  };

  useEffect(() => {
    const params = {
      page: 1,
      filter: sortType,
      type: requestType,
      resetData: true,
    };

    dispatch(reserRequests());
    dispatch(getAllRequests(params));
  }, [dispatch, sortType]);

  const requestSortOptions = !requestType
    ? REQUESTS_SORT_OPTIONS_PATHWAYS
    : requestType === REQUEST_TYPES.EVALUATION
    ? REQUESTS_SORT_OPTIONS_SPEECHCRAFT
    : APPROVALS_SORT_OPTIONS_PATHWAYS;

  return (
    <Container className='request-container'>
      <div role="navigation" aria-label="breadcrumbs">
        <NavBreadCrumbs>
          <li>
            <Link to={`${getPlatformName()}`}>Home</Link>
          </li>
          <li>
            <span className='separator'>/</span>
          </li>
          <li>
            {requestType === REQUEST_TYPES.APPROVAL
              ? 'Base Camp Manager'
              : 'Requests'}
          </li>
        </NavBreadCrumbs>
      </div>
      <FilterWrapper>
        <Typography variant='h3' component="h2">{`${
          requestType === REQUEST_TYPES.APPROVAL ? 'Approvals' : 'Requests'
        } ${data?.results ? `(${size(data?.results)})` : ''}`}</Typography>
        <SortFieldWrapper>
          <FormControlLabel
            label='Filter by : '
            labelPlacement='start'
            sx={{ minWidth: 120 }}
            className='h-40'
            size='small'
            control={
              <DropdownFieldV2
                value={sortType}
                options={requestSortOptions}
                handleChange={handleUpdateSortType}
                name='sortBy'
                className='v2'
                disabled={isLoading}
              />
            }
          />
        </SortFieldWrapper>
      </FilterWrapper>
      <CustomPaper square sx={{ py: 8 }}>
        {isEmpty(data) && isLoading ? (
          <RequestItemSkeleton />
        ) : isEmpty(data?.results) ? (
          <Typography variant='h3' textAlign='center'>
            No records found!
          </Typography>
        ) : (
          <InfiniteScroll
            dataLength={get(data, 'results', []).length}
            next={handleGetNextData}
            hasMore={get(data, 'next', '') ? true : false}
            loader={
              <div className='loader' key={0}>
                <RequestItemSkeleton />
              </div>
            }
            scrollableTarget='scrollableDiv'
          >
            <>
              {data?.results?.map((request: any) => (
                <RequestItem request={request} />
              ))}
            </>
          </InfiniteScroll>
        )}
      </CustomPaper>

      {reviewRequest.requestModalOpen ? (
        <ApprovalRequestModal
          title={reviewRequest.request.courseDisplayName}
          handleClose={handleModalClose}
        />
      ) : null}
      {reviewRequest.approvalModalOpen ? (
        <ApproveModal title='Add Comment' handleClose={handleModalClose} />
      ) : null}
      {reviewRequest.denyModalOpen ? (
        <DenyModal
          title={`Deny ${reviewRequest.request.type}`}
          handleClose={handleModalClose}
        />
      ) : null}
      <ConfirmRequest
        resetParams={{
          page: 1,
          filter: sortType,
          resetData: true,
          type: requestType,
        }}
      />
    </Container>
  );
};

export default memo(Requests);
