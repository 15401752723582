import { styled } from '@mui/material';
import { DeviceDown, DeviceUp } from 'styles/breakpoints';

export const FooterWrapper = styled('footer')`
  padding: 4px 0;
  text-align: center;
  border-top: 1px solid ${({ theme }) => theme.palette.ternary.main};

  p {
    margin: 0;
    font-size: 0.845rem;

    @media ${DeviceDown.md} {
      font-size: 0.7rem;
    }
  }
`;