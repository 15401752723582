import { FunctionComponent } from 'react';
import { SkeletonRequestItem } from './style';
import { Box, Skeleton } from '@mui/material';


interface RequestItemSkeletonProps {
  count?: number;
}

const RequestItemSkeleton: FunctionComponent<RequestItemSkeletonProps> = ({ count = 3 }) => {

  return (
    <Box className="request-item-skeletons">
      {
        [...Array(count)].map((_, index) => (
          <SkeletonRequestItem key={index}>
            <Skeleton variant="circular" className="avatar-img" />
            <Box className="request-content">
              <Skeleton variant="text" className="request-title" />
              <Skeleton variant="text" className="request-description" />
            </Box>
            <Box className="request-action">
              <Skeleton variant="rectangular" className="request-button" />
            </Box>
          </SkeletonRequestItem>
        ))
      }
    </Box>
  );
};

export default RequestItemSkeleton;
